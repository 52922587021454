import { render, staticRenderFns } from "./InventoryReportTotalAmountColumn.vue?vue&type=template&id=073a3934&scoped=true"
import script from "./InventoryReportTotalAmountColumn.vue?vue&type=script&lang=js"
export * from "./InventoryReportTotalAmountColumn.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "073a3934",
  null
  
)

export default component.exports